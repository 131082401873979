import ScrollOut from 'scroll-out';

export function initializeAnimations() {

    const RESET_DELAY = 100;

    function startAnimation(el) {
      el.classList.remove('ani--reset');
      el.removeAttribute('data-ani-resetting');
      
      // Force a reflow before adding the in-view class
      void el.offsetWidth;
      
      // Add the in-view class to start the animation
      el.classList.add('in-view');
    }
    
    function resetAnimation(el) {
      // Remove in-view class
      el.classList.remove('in-view');
      
      // Immediately set opacity to 0 and reset transform
      el.style.opacity = '0';
      //el.style.transform = 'none';
      
      // Add reset class and mark as resetting
      el.classList.add('ani--reset');
      el.setAttribute('data-ani-resetting', 'true');
      
      // Remove inline styles after a short delay
      setTimeout(() => {
        el.style.opacity = '';
        el.style.transform = '';
        
        // If element is in view again, restart animation
        if (el.getAttribute('data-scroll') === 'in') {
          startAnimation(el);
        } else {
          el.removeAttribute('data-ani-resetting');
        }
      }, RESET_DELAY);
    }
    
    ScrollOut({
      targets: '.ani:not(.ani-ignore)',
      once: false,
      threshold: 0.0,
      onShown: function(el) {
        // Check if the element is currently resetting
        if (el.getAttribute('data-ani-resetting') === 'true') {
          // If it's resetting, wait for reset to complete
          setTimeout(() => startAnimation(el), RESET_DELAY);
        } else {
          startAnimation(el);
        }
      },
      onHidden: function(el) {
        // Only reset if not already resetting
        if (el.getAttribute('data-ani-resetting') !== 'true') {
          resetAnimation(el);
        }
      }
    });
    


}