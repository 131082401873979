import Glide from '@glidejs/glide';

export function initializeCarousel() {
  const glide = new Glide('.carousel', {
    type: 'slider',
    startAt: 0,
    perView: 4,
    gap: 10,
    touchAngle: 45,
    swipeThreshold: 25,
    breakpoints: {
      1024: {
        perView: 3
      },
      768: {
        perView: 2
      },
      480: {
        perView: 1
      }
    },
    bound: true,
    rewind: false,
    animationDuration: 500
  });

  function updateSlideVisibility() {
    const slides = document.querySelectorAll('.carousel .glide__slide');
    const totalSlides = slides.length;
    const perView = glide.settings.perView;
    const currentIndex = glide.index;

    slides.forEach((slide, index) => {
      if (index >= currentIndex && index < currentIndex + perView) {
        slide.style.opacity = '1';
      } else {
        slide.style.opacity = '0';
      }
    });

    updateNavigationButtons(currentIndex, totalSlides, perView);
  }

  function updateNavigationButtons(currentIndex, totalSlides, perView) {
    const prevButton = document.querySelector('.carousel__nav-button--prev');
    const nextButton = document.querySelector('.carousel__nav-button--next');
    const navButtons = document.querySelector('.carousel__nav-buttons');

    if (totalSlides <= perView) {
      navButtons.style.display = 'none';
      return;
    }

    navButtons.style.display = '';

    prevButton.style.opacity = currentIndex === 0 ? '0.5' : '1';
    prevButton.disabled = currentIndex === 0;

    nextButton.style.opacity = currentIndex >= totalSlides - perView ? '0.5' : '1';
    nextButton.disabled = currentIndex >= totalSlides - perView;
  }

  function updatePagination() {
    const slides = document.querySelectorAll('.carousel .glide__slide');
    const totalSlides = slides.length;
    const perView = glide.settings.perView;
    const paginationContainer = document.querySelector('.carousel__pagination');

    if (totalSlides <= perView) {
      paginationContainer.style.display = 'none';
      return;
    }

    paginationContainer.style.display = '';

    const totalDots = totalSlides - perView + 1;
    paginationContainer.innerHTML = '';

    for (let i = 0; i < totalDots; i++) {
      const bullet = document.createElement('button');
      bullet.className = 'carousel__pagination-bullet';

      bullet.setAttribute('data-glide-dir', `=${i}`);
      bullet.setAttribute('aria-label', `Slide =${i}`);

      paginationContainer.appendChild(bullet);
    }

    updateActivePaginationDot();
  }

  function updateActivePaginationDot() {
    const bullets = document.querySelectorAll('.carousel__pagination-bullet');
    bullets.forEach((bullet, index) => {
      if (index === glide.index) {
        bullet.classList.add('active');
      } else {
        bullet.classList.remove('active');
      }
    });
  }

  function resetCarouselPosition() {
    glide.go('=0');
    updateSlideVisibility();
    updatePagination();
  }

  let resizeTimer;
  window.addEventListener('resize', () => {
    clearTimeout(resizeTimer);
    resizeTimer = setTimeout(() => {
      resetCarouselPosition();
    }, 250);
  });

  glide.on('mount.after', () => {
    updateSlideVisibility();
    updatePagination();
  });

  glide.on('run', () => {
    updateSlideVisibility();
    updateActivePaginationDot();
  });

  glide.on('resize', () => {
    setTimeout(() => {
      updatePagination();
      updateSlideVisibility();
    }, 0);
  });

  const prevButton = document.querySelector('.carousel__nav-button--prev');
  const nextButton = document.querySelector('.carousel__nav-button--next');

  prevButton.addEventListener('click', () => glide.go('<'));
  nextButton.addEventListener('click', () => glide.go('>'));

  glide.mount();

  return glide;
}