import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/climate-crisis/400.css';

import '../scss/main.scss';

import { initializeHeader } from './header';
import { initializeAnimations } from './animations';
import { initializeDateCounter } from './date-counter';
import { initializeVideoPlayer } from './video-player';
import { initializeCarousel } from './carousel';
import { initializeCookieNotice } from './cookie-notice';

document.addEventListener('DOMContentLoaded', function() {
    
    initializeHeader();
    initializeAnimations();
    initializeCookieNotice();

    // Initialize date counter if the countdown element exists
    if (document.querySelector('.countdown')) {
        initializeDateCounter();
    }

    // Initialize video player if there are video containers
    if (document.querySelectorAll('.video-container').length > 0) {
        initializeVideoPlayer();
    }

    // Initialize carousel if the carousel element exists
    if (document.querySelector('.carousel')) {
        initializeCarousel();
    }

    const heroSection = document.querySelector('.hero');
    
    function parallax() {
        if (heroSection) {
            const scrollPosition = window.scrollY;
            const parallaxSpeed = 0.1;
            heroSection.style.backgroundPositionY = `${scrollPosition * parallaxSpeed}px`;
        }
    }

    window.addEventListener('scroll', parallax);
    parallax();
});
