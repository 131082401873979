export function initializeDateCounter() {
    const countDownDate = new Date("Jan 1, 2025 00:00:00").getTime();

    function getPolishForm(number, forms) {
        if (number === 1) {
            return forms[0];
        } else if (number % 10 >= 2 && number % 10 <= 4 && (number % 100 < 10 || number % 100 >= 20)) {
            return forms[1];
        } else {
            return forms[2];
        }
    }

    function updateCountdown() {
        const now = new Date().getTime();
        const distance = countDownDate - now;

        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        updateElement("days", days, ['dzień', 'dni', 'dni']);
        updateElement("hours", hours, ['godzina', 'godziny', 'godzin']);
        updateElement("minutes", minutes, ['minuta', 'minuty', 'minut']);
        updateElement("seconds", seconds, ['sekunda', 'sekundy', 'sekund']);
    }

    function updateElement(id, value, forms) {
        const numberElement = document.getElementById(id);
        const labelElement = document.getElementById(`${id}-label`);
        
        if (numberElement) {
            let displayValue = value.toString().padStart(2, '0');
            if (displayValue !== '0' && displayValue.startsWith('0')) {
                displayValue = displayValue.slice(1);
            }
            numberElement.textContent = displayValue;
        }
        
        if (labelElement) {
            labelElement.textContent = getPolishForm(value, forms);
        }
    }

    setInterval(updateCountdown, 1000);
    updateCountdown();
}