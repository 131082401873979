export function initializeVideoPlayer() {
    const videoContainers = document.querySelectorAll('.video-container');

    videoContainers.forEach((container, index) => {
        const playButton = container.querySelector('.play-button');
        const loadingAnimation = container.querySelector('.loading-animation');
        const videoWrapper = container.querySelector('.video-wrapper');
        let player;

        if (playButton) {
            playButton.addEventListener('click', function() {
                playButton.style.display = 'none';
                if (loadingAnimation) loadingAnimation.style.display = 'flex';
                
                loadYouTubeAPI(index);
            });
        }
    });

    function loadYouTubeAPI(index) {
        if (typeof YT === 'undefined' || typeof YT.Player === 'undefined') {
            const tag = document.createElement('script');
            tag.src = "https://www.youtube.com/iframe_api";
            const firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
            
            window.onYouTubeIframeAPIReady = function() {
                createPlayer(index);
            };
        } else {
            createPlayer(index);
        }
    }

    function createPlayer(index) {
        const container = videoContainers[index];
        const videoId = container.dataset.videoId;
        const playerElement = container.querySelector('.video-player');

        const player = new YT.Player(playerElement, {
            height: '100%',
            width: '100%',
            videoId: videoId,
            playerVars: {
                'autoplay': 1,
                'controls': 1,
                'rel': 0,
                'showinfo': 0
            },
            events: {
                'onReady': onPlayerReady.bind(null, container)
            }
        });
    }

    function onPlayerReady(container, event) {
        const loadingAnimation = container.querySelector('.loading-animation');
        const videoWrapper = container.querySelector('.video-wrapper');

        if (loadingAnimation) loadingAnimation.style.display = 'none';
        if (videoWrapper) videoWrapper.style.display = 'block';
        
        container.style.backgroundImage = 'unset';
        container.style.background = '#000';
        
        event.target.playVideo();
    }
}